<template>
  <div>
    <h1 class="text-2xl mb-6">Publication ponctuelle</h1>
    <OneshotForm
      @update:url="handleUrlUpdate"
      @update:utm="handleUtmUpdate"
      @update:isBreakingNews="handleBreakingNewsUpdate"
    ></OneshotForm>
    <v-card class="mb-6 pa-2">
      <v-card-title>Aperçu</v-card-title>
      <v-row class="ma-3">
        <v-col cols="12">
          <v-text-field
            v-model="fullUrl"
            label="URL complète"
            outlined
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="ma-3">
        <v-col cols="6">
          <v-btn color="primary" @click="sendLink" :disabled="isLoading"
            >Envoyer le lien</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
    <!-- Loader -->
    <v-overlay :value="loader" absolute>
      <v-alert type="info" transition="scale-transition">
        <span>Envoi en cours...</span>
      </v-alert>
    </v-overlay>
    <!-- Results -->
    <v-dialog v-model="displayResults" max-width="600">
      <v-card>
        <v-card-title class="headline">{{ results.status }}</v-card-title>
        <v-card-text>
          <v-row v-if="results.status === 'success'">
            <v-col>
              <v-alert type="success" transition="scale-transition">
                <span>Le lien a été envoyé avec succès</span>
              </v-alert>
            </v-col>
          </v-row>
          <v-row v-else-if="results.status === 'error'">
            <v-col>
              <v-alert type="error" transition="scale-transition">
                <span>{{ results.output }}</span>
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="displayResults = false">Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card> </v-card>
  </div>
</template>
<script>
import useAxios from "@/hooks/useAxios";
import OneshotForm from "@/components/link-mess-apps/forms/oneshotForm.vue";
export default {
  name: "OneShotLink",
  components: {
    OneshotForm,
  },
  data() {
    return {
      url: "",
      utm: "utm_campaign=DarkSocial&utm_medium=Social&utm_source=Whatsapp",
      fullUrl: "",
      preview: "",
      isBreakingNews: false,
      loader: false,
      displayResults: false,
      results: { status: "", output: "" },
    };
  },
  setup() {
    const { axiosGet, axiosPost, isLoading } = useAxios();

    return {
      axiosGet,
      isLoading,
      axiosPost,
    };
  },
  methods: {
    handleUrlUpdate(newUrl) {
      this.url = newUrl;
      this.fullUrl = `${this.url}?${this.utm}`;
    },
    handleUtmUpdate(newUtm) {
      this.utm = newUtm;
      this.fullUrl = `${this.url}?${this.utm}`;
    },
    handleBreakingNewsUpdate(isBreakingNews) {
      this.isBreakingNews = isBreakingNews;
    },
    async sendLink() {
      if (this.url == "") {
        this.results = {
          status: "error",
          output: "Veuillez entrer une URL",
        };
        this.displayResults = true;
      } else {
        this.loader = true;
        const response = await this.axiosPost("/link_messapps/one_shot", {
          url: this.url,
          isBreakingNews: this.isBreakingNews,
        });
        // console.log(response);
        this.results = response.data;
        // console.log(this.results);
        this.loader = false;
        this.displayResults = true;
      }
    },
  },
};
</script>
<style></style>
